<template>
  <navbar />

  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Transaction Information
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Transaction details and the products.
        </p>
      </div>
      <div class="border-t border-gray-200" v-if="transaction">
        <dl>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Transaction Code</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ transaction.transaction_code }}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Customer Name</dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              v-if="transaction.customer"
            >
              {{ transaction.customer.name }}
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Customer Type</dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              v-if="transaction.customer"
            >
              {{ transaction.customer.type }}
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Grand Total</dt>
            <dd
              class="mt-1 text-sm text-gray-900 font-medium sm:mt-0 sm:col-span-2"
            >
              {{ currency(transaction.grand_total) }}
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Payment Status</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div class="flex">
                <span
                  class="mr-4 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full"
                  :class="{
                    'text-red-100 bg-red-900':
                      transaction.payment_status == 'canceled',
                    'text-red-100 bg-red-600':
                      transaction.payment_status == 'awaiting_payment',
                    'text-green-100 bg-green-600':
                      transaction.payment_status == 'paid',
                  }"
                  >{{ transaction.payment_status }}
                </span>
                <button
                  @click="showPaymentStatus = !showPaymentStatus"
                  v-if="
                    transaction.payment_status != 'canceled' || isOwner == 1
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    v-if="isOwnerAccess"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.228 5.228l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.728 3.728z"
                    />
                  </svg>
                </button>
              </div>
              <!---
              <div
                v-if="transaction.payment_status == 'canceled'"
                class="text-xs mt-2"
              >
                The stock of the products below have been restored.
              </div>
            -->

              <div class="mt-4" v-if="showPaymentStatus && isOwner != 1">
                <div v-if="transaction.payment_status == 'awaiting_payment'">
                  <button
                    @click="changePaymentStatus(transaction, 'paid', 'cash')"
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                  >
                    <span>Change to Paid (Cash)</span>
                  </button>

                  <button
                    @click="
                      changePaymentStatus(transaction, 'paid', 'transfer')
                    "
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                  >
                    <span>Change to Paid (Transfer)</span>
                  </button>
                </div>

                <button
                  v-if="transaction.payment_status == 'paid'"
                  @click="
                    changePaymentStatus(transaction, 'awaiting_payment', '')
                  "
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                >
                  <div v-if="loading" class="btn-spinner mt-1 mr-2" />
                  <span>Change to Awaiting Payment</span>
                </button>
              </div>

              <div class="mt-4" v-if="showPaymentStatus && isOwner == 1">
                <button
                  v-if="transaction.payment_status != 'paid'"
                  @click="changePaymentStatus(transaction, 'paid', 'cash')"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                >
                  <span>Change to Paid (Cash)</span>
                </button>

                <button
                  v-if="transaction.payment_status != 'paid'"
                  @click="changePaymentStatus(transaction, 'paid', 'transfer')"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                >
                  <span>Change to Paid (Transfer)</span>
                </button>
                <button
                  v-if="transaction.payment_status != 'awaiting_payment'"
                  @click="
                    changePaymentStatus(transaction, 'awaiting_payment', '')
                  "
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                >
                  <div v-if="loading" class="btn-spinner mt-1 mr-2" />
                  <span>Change to Awaiting Payment</span>
                </button>
                <button
                  v-if="transaction.payment_status != 'canceled'"
                  @click="changePaymentStatus(transaction, 'canceled', '')"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-4"
                >
                  <div v-if="loading" class="btn-spinner mt-1 mr-2" />
                  <span>Change to Canceled</span>
                </button>
              </div>
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Payment Method</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ transaction.payment_method }}
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Note</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span v-if="transaction.note == null">-</span>
              <span v-else>{{ transaction.note }}</span>

              <button
                v-if="canEditNote"
                @click="showChangeTransactionNote = !showChangeTransactionNote"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 ml-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.228 5.228l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.728 3.728z"
                  />
                </svg>
              </button>

              <div v-if="showChangeTransactionNote">
                <form @submit.prevent="changeTransactionNote">
                  <input
                    v-model="note"
                    type="text"
                    class="appearance-none rounded-none relative px-3 py-2 mt-2 mr-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                  <button
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <div v-if="loading" class="btn-spinner mt-1 mr-2" />
                    Save
                  </button>
                </form>
              </div>
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Transaction Date</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ transaction.created_at }}

              <button
                @click="showChangeTransactionDate = !showChangeTransactionDate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 ml-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.228 5.228l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.728 3.728z"
                  />
                </svg>
              </button>

              <div v-if="showChangeTransactionDate">
                <form @submit.prevent="changeTransactionDate">
                  <input
                    v-model="created_at"
                    type="date"
                    class="appearance-none rounded-none relative px-3 py-2 mt-2 mr-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                  <button
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <div v-if="loading" class="btn-spinner mt-1 mr-2" />
                    Save
                  </button>
                </form>
              </div>
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Term of Payment</dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              v-if="transaction.customer"
            >
              <span v-if="transaction.customer.term_of_payment">
                {{ transaction.customer.term_of_payment }}
              </span>
              <span v-else> - </span>
            </dd>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Created by</dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              v-if="transaction.user"
            >
              <span v-if="transaction.user.name">
                {{ transaction.user.name }}
              </span>
              <span v-else> - </span>
            </dd>
          </div>
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dt class="text-sm font-medium text-gray-500">Print Status</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span v-if="transaction.invoices?.length > 0"> Printed </span>
              <span v-else> - </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="flex flex-col mt-8 mb-4" v-if="transaction">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  class="hover:bg-gray-50"
                  v-for="detail in transaction.transactions"
                  :key="detail.id"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm text-gray-900">
                          {{ detail.product.name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="text-sm text-gray-900">
                      {{ currency(detail.price) }}
                    </span>
                    <button
                      v-if="authUserId == 6 || authUserId == 4"
                      @click="editPrice(detail)"
                      type="button"
                      class="font-medium text-red-600 hover:text-red-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.228 5.228l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.728 3.728z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="text-sm text-gray-900">
                      {{ detail.quantity }}
                    </span>
                    <button
                      v-if="
                        authUserId == 6 || authUserId == 4 || authUserId == 10
                      "
                      @click="editQuantity(detail)"
                      type="button"
                      class="font-medium text-red-600 hover:text-red-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.228 5.228l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.728 3.728z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap flex">
                    <div class="text-sm text-gray-900 flex-auto">
                      {{ currency(detail.total) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap" colspan="3">
                    <div class="flex justify-end">
                      <div class="ml-4">
                        <div
                          class="text-sm font-medium text-gray-900 text-right"
                        >
                          Grand Total
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 font-medium">
                      {{ currency(transaction.grand_total) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-40">
      <!-- <div class="flex-auto justify-start mb-5">
        <button
          @click="addProduct"
          class="
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Add Product
        </button>
      </div> -->
      <div class="flex justify-end mb-5">
        <button
          @click="printInvoice(transaction.id, 'AD SEAFOOD')"
          class="inline-flex justify-center py-2 px-4 mr-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Print Invoice (AD Seafood)
        </button>

        <button
          @click="printInvoice(transaction.id, 'AD SUPPLIER')"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Print Invoice (AD Supplier)
        </button>
      </div>
    </div>

    <add-product-modal v-if="showAddProductModal" />

    <edit-product-modal
      v-if="showEditProductModal"
      :current-quantity="currentQuantity"
      :selected-detail-id="selectedDetailId"
      :selected-transaction-id="this.$route.params.id"
      :selected-transaction-code="transaction.transaction_code"
      :selected-customer-name="transaction.customer.name"
      :selected-product-name="selectedProductName"
    />

    <edit-price-modal
      v-if="showEditPriceModal"
      :current-price="currentPrice"
      :selected-detail-id="selectedDetailId"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import AddProductModal from "@/views/transactions/AddProductModal";
import EditProductModal from "@/views/transactions/EditProductModal";
import EditPriceModal from "@/views/transactions/EditPriceModal";
import Cookie from "js-cookie";

export default {
  components: {
    Navbar,
    AddProductModal,
    EditProductModal,
    EditPriceModal,
  },
  data() {
    return {
      keyword: "",
      timer: null,
      showPaymentStatus: false,
      showChangeTransactionDate: false,
      showChangeTransactionNote: false,
      created_at: "",
      note: "",
      currentQuantity: "",
      currentPrice: "",
      selectedDetailId: "",
      selectedProductName: "",
      isOwner: Cookie.get("adpos_user_owner"),
      authUserId: Cookie.get("adpos_user_id"),
      isOwnerAccess: Cookie.get("adpos_user_role") == "owner",
      canEditNote:
        Cookie.get("adpos_user_role") == "owner" ||
        Cookie.get("adpos_user_role") == "admin_invoice",
    };
  },
  mounted() {
    this.getTransaction(this.$route.params.id);
  },
  computed: {
    ...mapState("transactionsHistory", {
      transaction: (state) => state.transaction,
      loading: (state) => state.loading,
      showAddProductModal: (state) => state.showAddProductModal,
      showEditProductModal: (state) => state.showEditProductModal,
      showEditPriceModal: (state) => state.showEditPriceModal,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
  },
  methods: {
    getTransaction(id) {
      this.$store.dispatch("transactionsHistory/getById", id);
    },
    changePaymentStatus(transaction, paymentStatus, paymentMethod) {
      this.$store.dispatch("transactionsHistory/changePaymentStatus", {
        transaction: transaction,
        payment_status: paymentStatus,
        payment_method: paymentMethod,
      });

      this.showPaymentStatus = false;
    },
    changeTransactionDate() {
      this.$store.dispatch("transactionsHistory/changeTransactionDate", {
        transaction: this.transaction,
        created_at: this.created_at,
      });

      this.showChangeTransactionDate = false;
    },
    changeTransactionNote() {
      this.$store.dispatch("transactionsHistory/changeTransactionNote", {
        transaction: this.transaction,
        note: this.note,
      });

      this.showChangeTransactionNote = false;
    },
    popupWindow(url, windowName, win, w, h) {
      const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
      const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
      return win.open(
        url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
    },
    printInvoice(transactionId, company) {
      const url = `${process.env.VUE_APP_BASE_URL_API}/transactions/invoice/${transactionId}/${company}`;
      const windowName = transactionId;

      this.popupWindow(url, windowName, window, 800, 500);
    },
    addProduct() {
      this.$store.dispatch("transactionsHistory/openAddProductModal", true);
    },
    editQuantity(detail) {
      this.currentQuantity = detail.quantity;
      this.selectedDetailId = detail.id;
      this.selectedProductName = detail.product.name;
      this.$store.dispatch("transactionsHistory/openEditProductModal", true);
    },
    editPrice(detail) {
      this.currentPrice = detail.price;
      this.selectedDetailId = detail.id;
      this.$store.dispatch("transactionsHistory/openEditPriceModal", true);
    },
  },
};
</script>
