<template>
  <navbar />
  <page-title title="History" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="mb-6 flex justify-between items-center">
      <div class="flex flex-col lg:flex-row items-center w-full">
        <div class="w-full rounded mr-1">
          <div>
            <label class="font-medium">Keyword:</label>
            <input
              v-model="this.keyword"
              autocomplete="off"
              type="text"
              name="search"
              placeholder="Code or customer name.."
              class="relative w-full px-4 py-2 mt-2 rounded border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
            />
          </div>
        </div>
        <div class="w-full rounded mr-1">
          <div>
            <label class="font-medium">Payment Status:</label>
            <select
              v-model="this.payment_status"
              id="payment_status"
              name="payment_status"
              class="relative w-full px-4 py-2 mt-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">-- Select --</option>
              <option value="paid">Paid</option>
              <option value="awaiting_payment">Awaiting Payment</option>
              <option value="canceled">Canceled</option>
            </select>
          </div>
        </div>

        <div class="w-full rounded mr-1">
          <div>
            <label class="font-medium">From:</label>
            <input
              v-model="this.start_date"
              autocomplete="off"
              type="date"
              name="start_date"
              placeholder="From"
              class="relative w-full px-4 py-2 mt-2 border border-gray-300 rounded placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
            />
          </div>
        </div>

        <div class="w-full rounded mr-1">
          <div>
            <label class="font-medium">To:</label>
            <input
              v-model="this.end_date"
              autocomplete="off"
              type="date"
              name="end_date"
              placeholder="To"
              class="relative w-full px-4 py-2 mt-2 border border-gray-300 rounded placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
            />
          </div>
        </div>

        <div class="w-full rounded mr-1">
          <div>
            <label class="font-medium">Term of Payment:</label>
            <select
              v-model="this.term_of_payment"
              id="term_of_payment"
              name="term_of_payment"
              class="relative w-full px-4 py-2 mt-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">-- Select --</option>
              <option value="cash / transfer">cash / transfer</option>
              <option value="1 week">1 week</option>
              <option value="2 weeks">2 weeks</option>
              <option value="30 days">30 days</option>
            </select>
          </div>
        </div>

        <div class="w-full rounded mr-1 mt-8">
          <div>
            <button
              @click="search"
              type="button"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div v-if="loading" class="btn-spinner mt-1 mr-2" />
              Search
            </button>

            <button
              @click="reset"
              type="button"
              class="ml-3 text-sm text-gray-500 hover:text-gray-700 focus:text-indigo-500"
            >
              Reset
            </button>
          </div>
        </div>

        <div class="w-full rounded flex justify-end mt-8">
          <button
            @click="exportExcel"
            type="button"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            v-if="selectedTransactionIds.length == 0"
          >
            Export to Excel
          </button>

          <button
            type="button"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            v-if="selectedTransactionIds.length > 0"
            @click="openNotesModal"
          >
            <div v-if="loadingUpdateBulk" class="btn-spinner mt-1 mr-2" />
            Change to Paid
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Transaction Code
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Grand Total
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Payment Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Payment Method
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Term of Payment
                  </th>
                  <th
                    v-if="isAdminOwnerAccess"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <input
                      type="checkbox"
                      class="w-5 h-5 text-blue-600 rounded-sm"
                      :disabled="
                        isSelectAllEnabled == false &&
                        (activePage !== currentPage || activePage == null)
                      "
                      :checked="isSelectAllChecked"
                      @change="toggleSelectAll"
                    />
                    <label class="ml-1">Select All</label>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Print Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Note
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="transaction in transactions.data"
                  :key="transaction.id"
                  class="hover:bg-gray-50 cursor-pointer"
                  :class="{
                    'bg-red-50 hover:bg-red-100':
                      transaction.payment_status === 'awaiting_payment',
                    'bg-yellow-300 hover:bg-yellow-300': isSelected(
                      transaction.id
                    ),
                  }"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ transaction.transaction_code }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ transaction.customer.name }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ currency(transaction.grand_total) }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ transaction.payment_status }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ transaction.payment_method }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ transaction.created_at }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    @click="openDetailTransaction(transaction.id)"
                  >
                    <div class="text-sm text-gray-900">
                      {{ transaction.customer.term_of_payment }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap"
                    v-if="isAdminOwnerAccess"
                  >
                    <div class="text-sm text-gray-900">
                      <input
                        type="checkbox"
                        class="w-5 h-5 text-blue-600 rounded-sm"
                        :checked="isSelected(transaction.id)"
                        @change="toggleSelected(transaction.id)"
                      />
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div
                      class="text-sm text-gray-900"
                      v-if="transaction.invoices?.length > 0"
                    >
                      Printed
                    </div>
                    <div class="text-sm text-gray-900" v-else>-</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.note }}
                    </div>
                  </td>
                </tr>
                <tr v-if="displayGrandTotal">
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-right font-bold text-gray-900">
                      Total
                    </div>
                    <!--<small v-if="this.$route.query.payment_status != 'canceled'"
                      >&nbsp;</small
                    >-->
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      {{ currency(sumGrandTotal) }}
                    </div>
                    <!--<small v-if="this.$route.query.payment_status != 'canceled'"
                      >The system is not counting the transaction that was
                      canceled.</small
                    >-->
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                  <td class="px-6 py-4 whitespace-nowrap">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="transactions.data">
      <pagination-transaction-history
        :links="transactions.meta.links"
        :active-page="this.$route.name"
        :search-keyword="this.$route.query.search"
        :payment-status="this.$route.query.payment_status"
        :start-date="this.$route.query.start_date"
        :end-date="this.$route.query.end_date"
        :term-of-payment="this.$route.query.term_of_payment"
      />
    </div>
  </div>

  <edit-notes-modal
    v-if="showEditNotesModal"
    :selected-transaction-ids="selectedTransactionIds"
  />
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import PageTitle from "@/components/PageTitle";
import PaginationTransactionHistory from "@/components/PaginationTransactionHistory";
import EditNotesModal from "@/views/transactions/EditNotesModal";
import Cookie from "js-cookie";

export default {
  components: {
    Navbar,
    PageTitle,
    PaginationTransactionHistory,
    EditNotesModal,
  },
  data() {
    return {
      keyword: "",
      payment_status: "",
      start_date: "",
      end_date: "",
      term_of_payment: "",
      isAdminOwnerAccess: Cookie.get("adpos_user_role") == "owner",
    };
  },
  mounted() {
    this.getTransactions();
    //this.getSumGrandTotal();
  },
  computed: {
    ...mapState("transactionsHistory", {
      transactions: (state) => state.transactions,
      sumGrandTotal: (state) => state.sumGrandTotal,
      loading: (state) => state.loading,
      loadingUpdateBulk: (state) => state.loadingUpdateBulk,
      selectedTransactionIds: (state) => state.selectedTransactionIds,
      showEditNotesModal: (state) => state.showEditNotesModal,
      isSelectAllEnabled: (state) => state.isSelectAllEnabled,
      activePage: (state) => state.activePage,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
    displayGrandTotal() {
      if (
        Object.keys(this.$route.query).length == 0 ||
        Object.keys(this.$route.query).length == 1
      ) {
        return false;
      }
      return true;
    },

    transactionIds() {
      // Check if transactions.data is defined before mapping
      return (this.transactions.data || []).map(
        (transaction) => transaction.id
      );
    },

    // Check if all transactions are selected
    isSelectAllChecked() {
      const transactionIds = this.transactions.data || []; // Ensure transactions is always an array
      return (
        transactionIds.length &&
        transactionIds.every((transaction) => this.isSelected(transaction.id))
      );
    },

    currentPage() {
      return this.transactions.meta.current_page;
    },
  },
  methods: {
    getTransactions() {
      this.$store.dispatch("transactionsHistory/getTransactions", {
        page: this.$route.query.page,
        search: this.$route.query.search,
        payment_status: this.$route.query.payment_status,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        term_of_payment: this.$route.query.term_of_payment,
      });
    },
    getSumGrandTotal() {
      this.$store.dispatch("transactionsHistory/sumGrandTotal", {
        payment_status: this.$route.query.payment_status,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        term_of_payment: this.$route.query.term_of_payment,
      });
    },
    search() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
          search: this.keyword,
          payment_status: this.payment_status,
          start_date: this.start_date,
          end_date: this.end_date,
          term_of_payment: this.term_of_payment,
        }),
      });

      this.$store.dispatch("transactionsHistory/getTransactions", {
        page: 1,
        search: this.keyword,
        payment_status: this.payment_status,
        start_date: this.start_date,
        end_date: this.end_date,
        term_of_payment: this.term_of_payment,
      });

      this.$store.dispatch("transactionsHistory/sumGrandTotal", {
        search: this.keyword,
        payment_status: this.payment_status,
        start_date: this.start_date,
        end_date: this.end_date,
        term_of_payment: this.term_of_payment,
      });
    },
    reset() {
      this.keyword = "";
      this.payment_status = "";
      this.start_date = "";
      this.end_date = "";
      this.term_of_payment = "";
      this.$store.dispatch("transactionsHistory/getTransactions", {
        page: 1,
        search: "",
      });

      this.$router.push({ name: "TransactionsHistory" });
    },
    openDetailTransaction(id) {
      this.$router.push({ name: "TransactionsDetail", params: { id: id } });
    },
    exportExcel() {
      let url = `${process.env.VUE_APP_BASE_URL_API}/transactions/export?search=${this.keyword}&payment_status=${this.payment_status}&start_date=${this.start_date}&end_date=${this.end_date}&term_of_payment=${this.term_of_payment}`;
      window.open(url);
    },
    isSelected(id) {
      return this.selectedTransactionIds.includes(id);
    },
    toggleSelected(id) {
      this.$store.dispatch("transactionsHistory/toggleSelectedTransactionId", {
        id: id,
      });
    },
    toggleSelectAll() {
      this.$store.dispatch("transactionsHistory/toggleSelectAll", {
        transactionIds: this.transactionIds,
        pageId: this.transactions.meta.current_page,
      });
    },
    openNotesModal() {
      this.$store.dispatch("transactionsHistory/openEditNotesModal", true);
    },
  },
  watch: {
    $route(to, from) {
      // Watch only the ?page query string
      if (to.query.page !== from.query.page) {
        this.getTransactions();
      }
    },
  },
};
</script>
