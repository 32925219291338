import Customer from "@/api/Customer";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    form: {
      name: "",
      type: "",
      phone: "",
      term_of_payment: "",
      address: "",
      area: "",
      sales_name: "",
      invoice_type: "",
    },
    customers: [],
    errors: [],
    loading: false,
  },

  mutations: {
    setCustomers(state, data) {
      state.customers = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setCustomerForm(state, formData) {
      state.form = formData;
    },
  },

  actions: {
    async getCustomers({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Customer.getAll(payload);

        commit("setCustomers", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async storeCustomer({ commit }, payload) {
      try {
        commit("setLoading", true);
        await Customer.store(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setCustomerForm", {});
        router.push({ name: "Customers" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async editCustomer({ commit }, payload) {
      try {
        commit("setErrors", []);
        commit("setLoading", true);
        const response = await Customer.getById(payload);

        commit("setCustomerForm", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async updateCustomer({ commit }, payload) {
      try {
        commit("setLoading", true);
        await Customer.update(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setCustomerForm", {});
        router.push({ name: "Customers" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    resetCustomerForm({ commit }) {
      commit("setCustomerForm", {});
    },
  },
};
